<template>
  <v-sheet class="newsEditor" ref="newsEditor">

    <v-sheet dark  class="header" >
      <div class="d-flex justify-space-between align-center fill-height pr-8">
        <div class="d-flex align-center fill-height">
          <v-sheet dark   class="fill-height d-flex align-center justify-center px-4" style="margin-bottom: 1px">
            <v-btn class="noCaps mr-5"  @click="$emit('close')" text  >
              <v-icon >mdi-chevron-left</v-icon>Назад</v-btn>
            <v-divider vertical />
          </v-sheet>

          <h4 class="font-weight-light pl-6">Створити новину</h4>
        </div>
        <div class="d-flex">
          <v-btn class="noCaps mr-4"  :disabled="loading"  text  >Попередный перегляд</v-btn>
          <v-btn class="noCaps" text  rounded :loading="loading" :disabled="loading"   @click="publish">
            <v-icon small class="mr-1" >mdi-check</v-icon>Опублікувати</v-btn>
        </div>
      </div>
    </v-sheet>

    <v-sheet dark class="sidemenu"   >
<!--      <h3 class="font-weight-light bitter pr-3 py-3 text-center">Налаштування</h3>-->
<!--      <v-divider  />-->
      <v-divider></v-divider>
      <div class="">

        <div>
          <div class="benefit_header d-flex align-center justify-space-between pa-3 mt-6 pl-6 pr-6"
               :class="benefits[0].visible ? 'benefit_header_active' : ''"
               @click="benefits[0].visible=!benefits[0].visible" >

            <h4 class=" font-weight-medium ">Головне Зображення</h4>
            <v-icon :dark="benefits[0].visible" icon
                    :class="benefits[0].visible ? 'benefit_button_active' : ''"
            >mdi-plus</v-icon>
          </div>
          <v-expand-transition mode="out-in">
            <div v-show="benefits[0].visible" >
              <div style="font-family: sans-serif; font-size:14px" class="font-weight-light pa-3 mt-3 pl-6 pr-6">

                <v-fade-transition mode="out-in">

                  <imago   :src="addData.image"
                           style="border: 3px solid #444"
                           width="auto" height="190px"  >

                    <div style="background-color: rgba(95,95,95,0.2);
                                position:absolute;
                                bottom: 0;
                                width: 100%;
                                color:white;
                                font-family:sans-serif"
                         class="font-weight-medium
                                caption" >

                      <v-btn @click="showImageEditor = true"
                             class="noCaps py-5"
                             dark
                             small
                             text
                             block  >
                        Змінити
                      </v-btn>
                    </div>
                  </imago>


                </v-fade-transition>
              </div>
            </div>
          </v-expand-transition>
        </div>
        <div>
          <div class="benefit_header d-flex align-center justify-space-between pa-3  pl-6 pr-6"
               :class="benefits[2].visible ? 'benefit_header_active' : ''"
               @click="benefits[2].visible=!benefits[2].visible" >
            <h4 class=" font-weight-medium ">Категорії та авторство</h4>
            <v-icon :dark="benefits[2].visible" icon
                    :class="benefits[2].visible ? 'benefit_button_active' : ''"
            >mdi-plus</v-icon>
          </div>
          <v-expand-transition mode="out-in">
            <div v-show="benefits[2].visible" >
              <div style="font-family: sans-serif; font-size:14px" class="font-weight-light pa-3 mt-3 pl-6 pr-6">
                <div class="d-flex justify-space-between align-center">
                  <span>Категорія:</span>
                  sdf
                </div>

                <div class="d-flex justify-space-between align-center mt-2">
                  <span>Підрозділ:</span>
                  ddv
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <span>Автор:</span>
                  ddv
                </div>

              </div>
            </div>
          </v-expand-transition>
        </div>
        <div>
          <div class="benefit_header d-flex align-center justify-space-between pa-3 pl-6 pr-6"
               :class="benefits[1].visible ? 'benefit_header_active' : ''"
               @click="benefits[1].visible=!benefits[1].visible" >
            <h4 class=" font-weight-medium ">Статус та Видимість</h4>
            <v-icon :dark="benefits[1].visible" icon
                    :class="benefits[1].visible ? 'benefit_button_active' : ''"
            >mdi-plus</v-icon>
          </div>
          <v-expand-transition mode="out-in">
            <div v-show="benefits[1].visible" >
              <div style="font-family: sans-serif; font-size:14px" class="font-weight-light pa-3 mt-3 pl-6 pr-6">
                <div class="d-flex justify-space-between align-center">
                  <span>Статус:</span>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="finet"> <b>{{ addData.status ?  addData.status : 'Чернетка' }}</b> </div>
                    </template>
                    <v-list>
                      <v-list-item-group>
                        <v-list-item
                            v-for="(item, index) in [{text:'Опублікувати',value:'published'},{text:'Чернетка',value:'draft'}]" :key="index" >
                          <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </div>

                <div class="d-flex justify-space-between align-center mt-2">
                  <span>Дата:</span>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="finet"> <b>2021-04-21</b> </div>
                    </template>
                    <v-list>
                      <v-list-item-group>
                        <v-list-item
                            v-for="(item, index) in [{text:'Опублікувати',value:'published'},{text:'Чернетка',value:'draft'}]" :key="index" >
                          <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </div>
                <div class="mt-8">
                  Постійне посилання:
                </div>
                <v-text-field v-model="addData.alias" outlined full-width hide-details class=" mt-2 " label="alias"></v-text-field>

                <div class="d-flex  mt-3 ">
                  <v-btn text small color="red lighten-2"  class="mt-5 ml-n3">Видалити</v-btn>
                </div>

              </div>
            </div>
          </v-expand-transition>
        </div>
        <div>
          <div class="benefit_header d-flex align-center justify-space-between pa-3 pl-6 pr-6"
               :class="benefits[3].visible ? 'benefit_header_active' : ''"
               @click="benefits[3].visible=!benefits[3].visible" >
            <h4 class=" font-weight-medium ">Оповіщення</h4>
            <v-icon :dark="benefits[3].visible" icon
                    :class="benefits[3].visible ? 'benefit_button_active' : ''"
            >mdi-plus</v-icon>
          </div>
          <v-expand-transition mode="out-in">
            <div v-show="benefits[3].visible" >
              <div style="font-family: sans-serif; font-size:14px" class="font-weight-light pa-3 mt-3 pl-6 pr-6">
                <div class="d-flex justify-space-between align-center">
                  <span>Telegram:</span>
                  sdf
                </div>

                <div class="d-flex justify-space-between align-center mt-2">
                  <span>Email:</span>
                  ddv
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <span>Автор:</span>
                  ddv
                </div>

              </div>
            </div>
          </v-expand-transition>
        </div>
        <br><br>



      </div>
    </v-sheet>

    <v-sheet class="content px-8"  ref="unfEl" >
      <VueEditor v-model="addData.title"
                 ref="titleQuill"
                 :editorOptions="{
                                   theme: 'bubble',
                                   height :'10px'}"
                 @text-change="$refs.titleQuill.quill.removeFormat(0,999999999)"
                 @focus="focusTitle"
                 @blur="unfocusTitle"
                 :class="titleEdit ? 'editTitle' : '' "
                 class="mt-8 mb-4 titleStyle" />

      <VueEditor  v-model="addData.content" class="mt-3 mb-16 pa-1 "
                  @blur="unfocusContent"
                  @focus="focusContent"
                  ref="contentQuill"
                  style=" color:#393939; "
                  :editorOptions="{ theme: 'bubble',bounds : $refs['newsEditor'] }" />
      <br>



      <v-dialog v-model="showImageEditor" width="1000">

        <v-card v-if="showImageEditor" dark>

          <v-card-title class="justify-space-between mt-3">
            <h3 style="line-height: 1.2" class="mt-3 mb-3  font-weight-light text-center">Редагувати головне зображення</h3>
            <v-btn plain icon  @click="showImageEditor = false"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>

          <v-card-text class="px-6 mt-3">
            <v-sheet style="height: 450px; overflow-y: auto" class="px-6" outlined>
              <editImage @close="showImageEditor=false"  v-model="addData.image" />
            </v-sheet>
          </v-card-text>

        </v-card>
      </v-dialog>


    </v-sheet>



  </v-sheet>
</template>

<script>
import {VueEditor} from "vue2-editor";
import {mapActions, mapState} from "vuex";
import imago from "@/components/UI/imago";
import editImage from "@/components/pages/businessDashboard/UI/editImage";

export default {
  name: "pageEditor",
  props : ['type','input'],
  components : {
    VueEditor,
    imago,
    editImage
  },
  data() {
    return {
      loading : false,

      showImageEditor:false,

      focus : false,
      addData: {},
      titleEdit : false,
      contentEdit : false,
      showUploadImage : false,
      benefits : [
        {visible:true, title: 'Головне зображення'  },
        {visible:true,title: 'Основні налаштування' },
        {visible:false,title: 'Оповіщення'          },
        {visible:false,title: 'Категорії'           },
      ],
    }
  },
  computed : {
    ...mapState('business', ['selectedBusiness']),
    blankNews() {
      return {
        alias    : 'draft_'+(new Date().getTime()),
        business : this.selectedBusiness.alias,
        title    : 'Назва',
        content  : 'Зміст',
        status   : 0
      }
    }
  },
  methods : {
    ...mapActions('business', [ 'GET_NEWS','CREATE_NEWS','EDIT_NEWS']),

    publish(){

      this.addData.status='published';
      this.addData.title = this.addData.title.replace('<p>','')
      this.addData.title = this.addData.title.replace('</p>','')

      this.loading = true;
      switch(this.addData.status) {
        case 'published' : this.addData.status = 1; break;
        case 'draft'     : this.addData.status = 0; break;
      }
      this.EDIT_NEWS({id:this.addData.id, data:this.addData})
          .then((out) => {
            this.loading = false;
            this.addData = out.data;
            this.$emit('refresh', out, this.type)
          })
          .catch(() => {
            this.loading = false;
          })
    },


    //technical
    focusTitle() {
      this.titleEdit=true
      if(this.addData.title === '<p>Назва</p>') { this.$refs.titleQuill.quill.root.innerHTML = null; this.$set(this.addData,'title', '') }
    },
    focusContent() {
      this.contentEdit=true
      if(this.addData.content === '<p>Зміст</p>') { this.$refs.contentQuill.quill.root.innerHTML = null; this.$set(this.addData,'content', '') }
    },
    unfocusTitle(){
      if(this.addData.title === '') { this.$set(this.addData,'title', 'Назва') }
      this.titleEdit=false
    },
    unfocusContent(){
      if(this.addData.content === '') { this.$set(this.addData,'content', 'Зміст') }
    },

    //technical



  },
  mounted() {
    this.$refs.titleQuill.quill.keyboard.bindings[13].unshift({
      key: 13,
      handler: () => { this.$refs.titleQuill.quill.blur() } });

    if(this.type === 'new' && !this.addData.uuid) {
      this.loading = true;
      this.CREATE_NEWS(this.blankNews)
          .then((out) => {
            this.loading = false;
            this.addData = out.data;
            this.$emit('refresh', out, this.type)
          })
          .catch(() => {
            this.loading = false;
          })
    }
    if( this.type === 'edit') {
      this.loading = true;
      this.GET_NEWS({business:this.selectedBusiness.alias, news:this.input}).then((out)=>{
        this.addData =  out.data;
        this.$store.commit('REFRESH');
        this.loading = false;
      }).catch(()=>{ this.loading = false;})
    }




  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/quill.bubble.css';
  .newsEditor {
    position : fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
  }
  .header {
    position:fixed;
    width: 100%;
    height: 40px;
    z-index:10;
  }
  .sidemenu {
    position:fixed;
    top:40px;
    bottom: 0;
    right: 0;
    width: 300px;
    overflow-y:auto;
    border-left: 1px solid #dddddd;
  }
  .content {
    position:relative;
    top:60px;
    bottom: 0;
    left: 0;
    margin-right: 300px;
    overflow-y:auto;
  }
  .titleStyle {
    color:#393939;
    font-weight: 600;
    font-family: 'Bitter',sans-serif !important;
    transition: all 0.4s ease-in-out;
  }
  .editTitle {
    background-color: #efeff8;

  }
  .ql-editor {
    min-height: 50px !important;
  }


.benefit_header {
  color: #d6d6d6 !important;
  transition: all .2s ease;
}
.benefit_header_active {
  background-color: #3b3b3b;
  color:white !important;

}
.benefit_button_active  {
  transform: rotate(45deg);
}
.benefit_header:hover{
  background-color: #3b3b3b;
  color:white !important;

  cursor: pointer;
}
.benefit_header:hover i{
  color:white !important;
}


</style>